import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/login-log',
            name: 'login-log',
            component: () => import("./login-log.vue")
        },
    ];
};