import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/supportoPrenotazioni',
            name: 'supportoPrenotazioni',
            component: () => import("./supportoPrenotazioni.vue")
        },
        {
            path: '/supportoPrenotazioni/:identifier',
            name: 'supportoPrenotazioniDetail',
            component: () => import("./supportoPrenotazioniDetail.vue")
        }
    ];
};