import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/company',
            name: 'companyList',
            component: () => import("./companyList.vue")
        },
        {
            path: '/company/:identifier',
            name: 'companyDetail',
            component: () => import("./companyDetail.vue")
        },
    ];
};