import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/categories',
            name: 'categories',
            meta: {
                showNavbar: true
            },
            component: () => import("./categorie.vue")
        }
    ];
};