import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/appuntamenti',
            name: 'appuntamenti',
            meta: {
                showNavbar: true
            },
            component: () => import("./appuntamenti.vue")
        },
    ];
};