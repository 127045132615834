import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/contracts',
            name: 'contractsList',
            component: () => import("./contractsList.vue")
        },
        {
            path: '/contracts/:identifier/:companyIdentifier?',
            name: 'contractsDetail',
            component: () => import("./contractsDetail.vue")
        },
    ];
};