import * as OM from '@/Model';
import * as Enum from '@/const';
import moment from 'moment';
import store from './store';
import filters from '@/filters';

export function JsonClone<T>(item: T){
    return <T>JSON.parse(JSON.stringify(item));
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized){}
        localized = new OM.LocalizedValue();

    if(!localized.values)
        localized.values = {};
        
    var enabledLanguages = store.state.consts.companyCodes;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function italyFixDateAndTime(value: any, time: string) : string {
    const date = new Date(value)

    var dateHour = date.getHours();

    if(dateHour != 0 && dateHour > 12){
        date.setDate(date.getDate() + 1); // Aggiungi un giorno
        date.setHours(0, 0, 0, 0);           // Imposta l'ora a mezzanotte
    } else if(dateHour != 0 && dateHour < 12){
        date.setHours(0, 0, 0, 0);// Imposta l'ora a mezzanotte
    }
    
    const hour = parseInt(time.substring(0, 2));
    const minutes = parseInt(time.substring(3, 5));
    
    date.setHours(date.getHours() + hour);
    date.setMinutes(date.getMinutes() + minutes);

    // Utilizza la data di riferimento specificata, se fornita
    const currentDate = new Date(value);

    // Converti la data corretta in fuso orario italiano rispetto alla data di riferimento
    const italyDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Europe/Rome" }));

    // Calcola la differenza in millisecondi
    const diff = italyDate.getTime() - currentDate.getTime();

    // Adegua la data italiana sottraendo la differenza
    date.setTime(date.getTime() - diff);
    
    return date.toISOString();
}

export function getUniversalISOStringDate(input: Date | string): string {
    const data: string = JSON.parse(JSON.stringify(input))
  
    const result = new Date(data)
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  
    return result.toISOString()
  }
  
  export function getUniversalDate(input: any): Date {
    const data = new Date(input)
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset())
  
    return data
  }