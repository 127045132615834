class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary(){
        let ris = {};
        for(let p in this){
            ris[p] = this[p];
        }
        return ris;
    }
}

export function getAllCategories(){
    var result = ContentCategories.GetAll();
    result.push(...RestrictedContentCategories.GetAll())

    return result;
}

export function EnumToList(inputEnum: any): { [key: string]: number | string } {
    let ris: any = {};
    for(var prop in inputEnum){
        if (typeof inputEnum[prop] === 'number') {
            ris[inputEnum[prop]] = prop.replace(/_/g, ' ');
        }
    }
    return ris;
}

export class AppRole extends Enum
{
    static Admin: string = "Admin";
    static Consulente: string = "Consulente";
    static User: string = "User";
}

export class DayOfWeek extends Enum
{
    static Domenica: number = 0;
    static Lunedì: number = 1;
    static Martedì: number = 2;
    static Mercoledì: number = 3;
    static Giovedì: number = 4;
    static Venerdì: number = 5;
    static Sabato: number = 6;
}

export class ContentCategories extends Enum
{
    static Psicologia: string = "Psicologia";
    static Nutrizione: string = "Nutrizione";
    static Sport: string = "Sport";
    static Genitorialita: string = "Genitorialita";
    static Caregiving: string = "Caregiving";
    static TerapiaAntiFumo: string = "Terapia Anti-Fumo";
    static SupportoPsicologico: string = "Supporto Psicologico";
    static StressPostTraumatico: string = "Stress Post-Traumatico";
    static EducatorePosturale: string = "Educatore Posturale";
    static EcoCounsellor: string = "Eco-Counsellor";

    static PrimaCheArriviIlBimbo: string = "Prima che arrivi il bimbo";
    static EsperienzaDellAborto: string = "Esperienza dell aborto";
    static EducazioneAlimentare: string = "Educazione alimentare";
    static SeIlBimboNonArriva: string = "E se il bimbo non arriva";
    static EssereGenitoriInDue: string = "Essere genitori in due";
    static CriticitaDellAdolescenza: string = "Criticita dell adolescenza";
    static PrimiDueAnni: string = "I primi due anni";
    static StileGenitoriale: string = "Il mio stile genitoriale";
    static Pediatra: string = "Pediatra";
    static Pedagogista: string = "Pedagogista";
    static EducatoreFinanziario: string = "Educatore Finanziario";
    static MindfulnessMeditazione: string = "Mindfulness e meditazione";
    static MolestieEViolenzaDiGenere: string = "Molestie e violenza di genere";
    static CoachRiabilitativo: string = "Coach riabilitativo";
}

export class RestrictedContentCategories extends Enum
{
    static Coach: string = "Coach";
}

export class ContentType extends Enum
{
    static Audio: string = "Audio";
    static Video: string = "Video";
    static Testo: string = "Testo";
}

export class ItalianRegion
{
    static Abruzzo: string = "Abruzzo";
    static Basilicata: string = "Basilicata";
    static Calabria: string = "Calabria";
    static Campania: string = "Campania";
    static Emilia_Romagna: string = "Emilia-Romagna";
    static Friuli_Venezia_Giulia: string = "Friuli-Venezia Giulia";
    static Lazio: string = "Lazio";
    static Liguria: string = "Liguria";
    static Lombardia: string = "Lombardia";
    static Marche: string = "Marche";
    static Molise: string = " Molise";
    static Piemonte: string = "Piemonte";
    static Puglia: string = "Puglia";
    static Sardegna: string = "Sardegna";
    static Sicilia: string = "Sicilia";
    static Toscana: string = "Toscana";
    static Trentino_Alto_Adige: string = "Trentino-Alto Adige";
    static Umbria: string = "Umbria";
    static Valle_d_Aosta: string = "Valle d'Aosta";
    static Veneto: string = "Veneto";
}

export function getAllItalianRegion(){
    return [
        ItalianRegion.Abruzzo,
        ItalianRegion.Basilicata,
        ItalianRegion.Calabria,
        ItalianRegion.Campania,
        ItalianRegion.Emilia_Romagna,
        ItalianRegion.Friuli_Venezia_Giulia,
        ItalianRegion.Lazio,
        ItalianRegion.Liguria,
        ItalianRegion.Lombardia,
        ItalianRegion.Marche,
        ItalianRegion.Molise,
        ItalianRegion.Piemonte,
        ItalianRegion.Puglia,
        ItalianRegion.Sardegna,
        ItalianRegion.Sicilia,
        ItalianRegion.Toscana,
        ItalianRegion.Trentino_Alto_Adige,
        ItalianRegion.Umbria,
        ItalianRegion.Valle_d_Aosta,
        ItalianRegion.Veneto
    ]
}