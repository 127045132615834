
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { ModalServices } from '@/services/ModalServices';
import { StorageServices } from './services/StorageServices';
import { CommonServices } from './services/CommonServices';
import { LoginServices } from './services/LoginServices';
import { LocalizationClient, LocalizedClient, LoginClient } from './services/Services';
import router from './router';
import store from './store';

@Options({})
export default class App extends Vue {

    loading: boolean = true;
    
    mounted(){
        Promise.all([
            LocalizedClient.getAllCompanyCodes(),
            LocalizedClient.getAll()
        ])
        .then(xs => {
            store.state.consts.companyCodes = xs[0];
            store.state.consts.localizedValues = xs[1];

            ModalServices.setOpModal(this.$opModal);
            let token = CommonServices.getAuthToken();
            if(token){
                LoginClient.refreshLogin()
                .then(x => {
                    LoginServices.loginCallback(x)
                    .then(x => {
                        this.loading = false;
                    });
                })
                .catch(err => {
                    this.loading = false;
                })
            } else {
                this.loading = false;
            }
        })
    }

}
