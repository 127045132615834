import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/consulenze',
            name: 'consulenze',
            component: () => import("./consulenze.vue")
        },
        {
            path: '/consulenze/:identifier',
            name: 'consulenzeDetail',
            component: () => import("./consulenzeDetail.vue")
        },
    ];
};