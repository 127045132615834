import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/pacchetti/:userIdentifier',
            meta: {
                showNavbar: true
            },
            component: () => import("./pacchetti.vue")
        },
    ];
};