import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/appUsers',
            name: 'appUsers',
            meta: {
                showNavbar: true
            },
            component: () => import("./appUsers.vue")
        },
        {
            path: '/backofficeUsers',
            name: 'backofficeUsers',
            meta: {
                showNavbar: true
            },
            component: () => import("./backofficeUsers.vue")
        },
        {
            path: '/users/:identifier',
            name: 'userDetail',
            meta: {
                showNavbar: true
            },
            component: () => import("./userDetail.vue")
        },
        {
            path: '/users/:identifier/calendario',
            name: 'calendario',
            meta: {
                showNavbar: true
            },
            component: () => import("./calendario.vue")
        },
    ];
};