import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/prenotazioni-restricted',
            name: 'prenotazioniRestricted',
            component: () => import("./prenotazioni.vue")
        },
        {
            path: '/prenotazioni-restricted/:identifier',
            name: 'prenotazioniRestrictedDetail',
            component: () => import("./prenotazioniDetail.vue")
        },
        {
            path: '/prenotazioni-restricted/user/:identifier',
            name: 'prenotazioniRestrictedUser',
            component: () => import("./prenotazioniUser.vue")
        }
    ];
};