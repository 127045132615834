import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/webinar',
            name: 'webinar',
            component: () => import("./webinar.vue")
        },
        {
            path: '/webinar/:identifier',
            name: 'webinarDetail',
            component: () => import("./webinarDetail.vue")
        },
    ];
};