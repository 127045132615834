
import {  Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { CompanyClient } from '@/services/Services';

@Options({})
export default class companyCodeSelector extends mixins(WithModel) {

    @Prop() label: string;

    codes: OM.TextIdentifier2[] = [];
    search: string = "";

    @Watch('search')
    onSearchChange(next, prev){
        if(this.search.length > 2){
            CompanyClient.searchCompanyCode(this.search)
            .then(x => {
                this.codes = x;
            })
        } else {
            this.codes = [];
        }
    }

}
