import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'login',
            component: () => import("./views/login.vue")
        },
        {
            path: '/register',
            name: 'register',
            component: () => import("./views/register.vue")
        },
        {
            path: '/reset_password',
            name: 'reset_password',
            component: () => import("./views/resetPassword.vue")
        },
        {
            path: '/change_password/:tempPassword/:username',
            name: 'change_password',
            component: () => import("./views/changePassword.vue")
        }
    ];
};