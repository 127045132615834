import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/academy',
            name: 'academyList',
            component: () => import("./academyList.vue")
        },
        {
            path: '/academy/:identifier',
            name: 'academyDetail',
            component: () => import("./academyDetail.vue")
        },
    ];
};