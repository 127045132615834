
import * as OM from '@/Model';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import ConfigModal from './configModal.vue';

@Options({})
export default class datiAggiuntivi extends mixins(WithModel) {

    showTempName: boolean = false;
    tempName: string = "";

    get canAddTempField(){
        let isUnique = true;
        for(let p in this.modelValue){
            if(p == this.tempName){
                isUnique = false;
            }
        }
        return this.tempName.trim() != "" && isUnique;
    }

    addTempField(){
        if(!this.canAddTempField)
            return;

        this.tempName = this.tempName.trim();
        this.localValue[this.tempName] = new OM.AdditionalFieldConfig();
        console.log("this.localValue", this.localValue);
        this.showTempName = false;
        this.tempName = "";
    }

    openConfigModal(config: OM.AdditionalFieldConfig){
        this.$opModal.show(ConfigModal, {
            config: config
        })
    }

    remove(key: string){
        delete this.localValue[key];
    }

}
