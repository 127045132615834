import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/prenotazioni',
            name: 'prenotazioni',
            component: () => import("./prenotazioni.vue")
        },
        {
            path: '/prenotazioni/:identifier',
            name: 'prenotazioniDetail',
            component: () => import("./prenotazioniDetail.vue")
        },
        {
            path: '/prenotazioni/user/:identifier',
            name: 'prenotazioniUser',
            component: () => import("./prenotazioniUser.vue")
        },
        {
            path: '/prenotazioni/user/:identifier/new',
            name: 'prenotazioniNew',
            component: () => import("./prenotazioniNew.vue")
        },
    ];
};