import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Cerca",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
    }, null, 512), [
      [_vModelText, _ctx.search]
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "form-control",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event)),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitModel(_ctx.modelValue)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codes, (item, i) => {
        return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item.name), 1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.modelValue]
    ])
  ]))
}