import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import begin from '@/views/begin';
import home from '@/views/home';
import appuntamenti from '@/views/appuntamenti';
import users from '@/views/users';
import academy from '@/views/academy';
import webinar from '@/views/webinar';
import consulenze from '@/views/consulenze';
import company from '@/views/company';
import prenotazioni from '@/views/prenotazioni';
import loginLog from '@/views/login-log';
import espertoRisponde from '@/views/espertoRisponde';
import appconfig from '@/views/appconfig';
import faq from '@/views/faq';
import listaAttesa from '@/views/lista-attesa';
import dictionary from '@/views/dictionary';
import contracts from '@/views/contracts';
import supportoPrenotazioni from '@/views/supportoPrenotazioni';
import pacchetti from '@/views/pacchetti';
import prenotazioniRestricted from '@/views/prenotazioni-restricted';
import categorie from '@/views/categorie';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("../views/layout/layout.vue"),
        children: [
            ...home.routes(),
            ...appuntamenti.routes(),
            ...users.routes(),
            ...consulenze.routes(),
            ...company.routes(),
            ...contracts.routes(),
            ...academy.routes(),
            ...espertoRisponde.routes(),
            ...webinar.routes(),
            ...prenotazioni.routes(),
            ...supportoPrenotazioni.routes(),
            ...prenotazioniRestricted.routes(),
            ...loginLog.routes(),
            ...appconfig.routes(),
            ...faq.routes(),
            ...listaAttesa.routes(),
            ...dictionary.routes(),
            ...pacchetti.routes(),
            ...categorie.routes()
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
})


router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default router
