
import WithModel from "@/mixins/withModel";
import { nextTick } from "vue";
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from "vue-property-decorator";

@Options({})
export default class autoTextarea extends mixins(WithModel) {
    @Prop() placeholder;
    @Prop({
        default: 2
    }) rows: number;
    @Prop({
        default: 0
    }) cols: number;
    @Prop({
        default: 50
    }) minHeight: number;
    @Prop() maxHeight: number;
    @Prop({
        default: true
    }) autoResize: boolean;

    textareaContent: string = "";
    height: string = "";
    isScrollEnabled: boolean = false;

    get styles() {
        return {
            resize: this.autoResize ? "none !important" : "",
            padding: `5${this.unit}`,
            height: this.height,
            overflow: `${this.isScrollEnabled ? "scroll" : "hidden"} !important`,
        };
    }
    get unit() {
        return "px";
    }
    @Watch('textareaContent')
    onTextareaContentChange(next, prev) {
        this.emitModel(this.textareaContent);
        this.resize();
    }

    resize() {
        const element: any = this.$refs.textarea;
        this.height = "auto !important";
        nextTick(() => {
            if (this.minHeight) {
                this.height = `${element.scrollHeight < this.minHeight ? this.minHeight : element.scrollHeight}${this.unit}`;
            }
            if (this.maxHeight) {
                if (element.scrollHeight > this.maxHeight) {
                    this.height = `${this.maxHeight}${this.unit}`;
                    this.isScrollEnabled = true;
                } else {
                    this.isScrollEnabled = false;
                }
            }
        })
    }

    created() {
        nextTick(() => {
            this.textareaContent = this.modelValue;
        })
    }
    mounted() {
        this.resize();
    }
};
