
import * as OM from '@/Model';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class configModal extends mixins(WithModel) {

    @Prop() config: OM.AdditionalFieldConfig;

    addRisposta(){
        this.config.options.push("");
    }
    removeRiposta(i: number){
        this.config.options.splice(i, 1);
    }

}
