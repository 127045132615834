import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/lista-attesa',
            name: 'lista-attesa',
            component: () => import("./lista-attesa.vue")
        },
    ];
};