import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/home',
            name: 'home',
            meta: {
                showNavbar: true
            },
            component: () => import("./home.vue")
        },
    ];
};