import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/domande',
            name: 'domande',
            component: () => import("./domande.vue")
        },
        {
            path: '/domande/:identifier',
            name: 'domandeDetail',
            component: () => import("./domandeDetail.vue")
        },
    ];
};