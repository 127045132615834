
import * as OM from '@/Model';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, ConsulenteClient, PrenotazioneClient, PrenotazioneRestrictedClient, UserClient } from '@/services/Services';
import store from '@/store';
import moment from 'moment';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class calendarioConsulente extends Vue {

    @Prop() consulenteIdentifier: string;

    selectedDate: string = "";
    fasceOrarie: OM.FasciaOraria[] = [];
    meseText: string = '';
    mese: any = null;
    orari: OM.OrariPerPrenotareVm[] = [];
    prenotazioniRicevute: OM.Prenotazione[] = [];
    prenotazioniOfDate: OM.Prenotazione[] = [];
    loaded: boolean = false;

    prenotazioniRestrictedRicevute: OM.PrenotazioneRestricted[] = [];
    prenotazioniRestrictedOfDate: OM.PrenotazioneRestricted[] = [];

    meseFrom: string = "";
    meseTo: string = "";

    created(){
        this.mese = moment().add(0, 'month');
        this.search();
    }

    search(){
        this.meseText = this.mese.toISOString();
        this.meseFrom = this.mese.startOf('month').toISOString();
        this.meseTo   = this.mese.endOf('month').toISOString();
        this.loaded = false;
        this.selectedDate = "";
        Promise.all([
            PrenotazioneClient.getCalendarOfConsulente(this.consulenteIdentifier, this.meseFrom, this.meseTo, store.state.company.companyCode),
            PrenotazioneClient.getByDateAndConsulente(this.consulenteIdentifier, this.meseFrom, this.meseTo),
            PrenotazioneRestrictedClient.getByDateAndConsulente(this.consulenteIdentifier, this.meseFrom, this.meseTo)
        ])
        .then(xs => {
            this.orari = xs[0];
            this.prenotazioniRicevute = xs[1];
            this.prenotazioniRestrictedRicevute = xs[2];
            this.loaded = true;
        })
    }

    getDayClasses(day, orari: OM.OrariPerPrenotareVm[]){
        let ris = day.classes.slice();
        let orarioDate = orari.find(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        let prenotazioni = this.prenotazioniRicevute.filter(x => 
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('date') == moment(day.date).get('date') &&
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('month') == moment(day.date).get('month')
        );
        let prenotazioniRestricted = this.prenotazioniRestrictedRicevute.filter(x => 
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('date') == moment(day.date).get('date') &&
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('month') == moment(day.date).get('month')
        );
        let prenotazioniFull = false;
        if(orarioDate){
            prenotazioniFull = prenotazioni.length > 0 && orarioDate.fasceOrarie.length == 0;
        }
        if(prenotazioniFull){
            ris.push('full');
        } else if(prenotazioni.length > 0 || prenotazioniRestricted.length > 0){
            ris.push('prenotato');
        } else if(orarioDate){
            if(orarioDate.fasceOrarie.length > 0){
                ris.push('available');
            }
        }
        let dayOfWeek = moment(day.date).day();
        if(dayOfWeek == 0 || dayOfWeek == 6){
            ris.push('weekend');
        }

        return ris;
    }

    checkDate(date: Date, orari: OM.OrariPerPrenotareVm[]){
        this.fasceOrarie = [];
        
        let orarioDate = orari.find(x => 
            moment(x.date).get('date') == moment(date).get('date') &&
            moment(x.date).get('month') == moment(date).get('month')
        );
        
        let prenotazioni = this.prenotazioniRicevute.filter(x => 
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('date') == moment(date).get('date') &&
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('month') == moment(date).get('month')
        );
        
        let prenotazioniRestricted = this.prenotazioniRestrictedRicevute.filter(x => 
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('date') == moment(date).get('date') &&
            moment(this.$utils.italyFixDateAndTime(x.data, x.orario.from)).get('month') == moment(date).get('month')
        );

        if((!orarioDate || orarioDate.fasceOrarie.length == 0) && prenotazioni.length == 0 && prenotazioniRestricted.length == 0){
            return;
        }

        this.selectedDate = new Date(date).toISOString();
        this.prenotazioniOfDate = prenotazioni;
        this.prenotazioniRestrictedOfDate = prenotazioniRestricted;
        if(orarioDate){
            this.fasceOrarie = orarioDate.fasceOrarie;
        }
    }

    mesePrev(){
        this.mese = this.mese.subtract(1, 'month');
        this.search();
    }

    meseNext(){
        this.mese = this.mese.add(1, 'month');
        this.search();
    }

    goToPrenotazione(item: OM.Prenotazione){
        this.$router.push('/prenotazioni/' + item.identifier);
    }

    goToPrenotazioneRestricted(item: OM.PrenotazioneRestricted){
        this.$router.push('/prenotazioni-restricted/' + item.identifier);
    }
}
