import { StorageServices } from '@/services/StorageServices';
import { createStore } from 'vuex'
import * as OM from '@/Model';
import * as CONST from '@/const'

let vuestore = {
    state: {
        user: <OM.User>null,
        company: <OM.Company>null,
        appCode: '',
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[],
            companyCodes: <string[]>[]
        },
        showSpinner: 0,
        isMobile: false,
        newNotificationCount: 0,
        appTitle: 'Welfood',
        beforeNavCbs: [],
        afterNavCbs: [],
        notification: null,
        notificationCallback: (payload: any) => {
            console.log("notification received", payload);
        },
        clipboard: <any>{},
        isAdmin: () => {
            return vuestore.state.user && vuestore.state.user.role == CONST.AppRole.Admin;
        },
        isConsulente: () => {
            return vuestore.state.user && vuestore.state.user.role == CONST.AppRole.Consulente;
        },
        isUser: () => {
            return vuestore.state.user && vuestore.state.user.role == CONST.AppRole.User;
        }
    }
}

let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '';

if(subdomain != 'app'){
    vuestore.state.appCode = subdomain;
}
if(!vuestore.state.appCode){
    vuestore.state.appCode = "wftest";
}

vuestore.state.isMobile = window.innerWidth <= 768;
window.addEventListener('resize', () => {
    vuestore.state.isMobile = window.innerWidth <= 768;
})

let store = createStore(vuestore);
export default store