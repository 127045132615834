import * as OM from '@/Model';
import * as CONST from '@/const';
import * as VM from '@/viewModel';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import router from '@/router';
import { CompanyClient, LoginClient, UserClient } from './Services';
import store from '@/store';

class _LoginServices {
    
    loginCallback = (loginResponse: OM.LoginResponse) => {
        return new Promise<void>((resolve, reject) => {
            CommonServices.setAuthToken(loginResponse.token);
            StorageServices.setUserIdentifier(loginResponse.userIdentifier);
            Promise.all([
                UserClient.getById(loginResponse.userIdentifier),
                CompanyClient.getById(loginResponse.companyIdentifier),
            ])
            .then(x => {
                if(x[0].role == CONST.AppRole.User){
                    reject();
                }
                store.state.user = x[0];
                store.state.company = x[1];
                resolve();
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            resolve();
        })
        return prom;
    }

}

export let LoginServices = new _LoginServices();